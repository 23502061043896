import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Popup from 'reactjs-popup';
import 'reactjs-popup/dist/index.css'; // Ensure you have this for default popup styles if you're using them

export default function StudentProfiles() {
  const [categoryTeacher, setCategoryTeacher] = useState(false);
  const [categories, setCategories] = useState([]);
  const [name, setName] = useState([]);
  const [users, setUsers] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [selectedUserId, setSelectedUserId] = useState('');
  const [message, setMessage] = useState('');

  useEffect(() => {
    const fetchCategoriesAndUsers = async () => {
      try {
        const categoriesResponse = await axios.get(`${process.env.REACT_APP_CLOSET_API}/products/admin`);
        setCategories(categoriesResponse.data);
        const usersResponse = await axios.get(`${process.env.REACT_APP_CLOSET_API}/user/users`);
        setUsers(usersResponse.data);
      } catch (error) {
        setMessage(`Error fetching data: ${error.response ? error.response.data.message : error.message}`);
      }
    };
    fetchCategoriesAndUsers();
  }, []);

  const handleFormSubmit = async (e) => {
    e.preventDefault();

    console.log(selectedUserId);
    try {
      const response = await axios.post(`${process.env.REACT_APP_CLOSET_API}/products/admin`, { msId: selectedUserId,name});
      setCategories([...categories, response.data]);
      setMessage(`Admin ${response.data.name} created successfully!`);
      setSelectedUserId('');
      setCategoryTeacher(false);
    } catch (error) {
      setMessage(`Error creating admin: ${error.response ? error.response.data.message : error.message}`);
    }
  };

  const handleDelete = async (id) => {
    try {
      await axios.delete(`${process.env.REACT_APP_CLOSET_API}/products/admin/${id}`);
      setCategories(categories.filter(category => category._id !== id));
      setMessage('Admin deleted successfully!');
    } catch (error) {
      setMessage(`Error deleting admin: ${error.response ? error.response.data.message : error.message}`);
    }
  };

  const filteredUsers = users.filter(user => 
    user.name.toLowerCase().includes(searchTerm.toLowerCase())
  );

  return (
    <>
      {message && <div className="text-red-500 text-sm p-3">{message}</div>}
      <div className="relative flex flex-col min-w-0 break-words bg-white w-full mb-6 shadow-lg rounded">
        <div className="rounded-t mb-0 px-4 py-3 border-0">
          <div className="flex flex-wrap items-center justify-between">
            <h3 className="font-semibold text-base text-blueGray-700">
              Teacher Admins
            </h3>
            <Popup
              trigger={<button className="bg-gray-800 text-gray-100 font-semibold py-2 px-4 rounded hover:bg-gray-700 transition ease-in-out duration-150">+ Add Admin</button>}
              modal
              nested
              overlayStyle={{ background: 'rgba(0,0,0,0.5)' }}
            >
              {close => (
                <div className="modal bg-white rounded-lg shadow-lg max-w-md mx-auto mt-10 p-8">
                  <div className="header mb-4">
                    <div className="font-semibold text-xl text-gray-800">Add New Admin</div>
                  </div>
                  <div className="flex flex-col">
                    <input 
                      className="mb-4 border border-gray-300 rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:border-gray-500" 
                      placeholder="Search User by Name" 
                      type="text" 
                      onChange={(e) => setSearchTerm(e.target.value)} 
                    />
                    <div style={{ maxHeight: '200px', overflowY: 'auto' }}>
                      <table className="min-w-full divide-y divide-gray-200">
                        <thead className="bg-gray-50">
                          <tr>
                            <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                              Name
                            </th>
                            <th scope="col" className="relative px-6 py-3">
                              <span className="sr-only">Add</span>
                            </th>
                          </tr>
                        </thead>
                        <tbody className="bg-white divide-y divide-gray-200">
                          {filteredUsers.map((user) => (
                            <tr key={user._id} className="hover:bg-gray-50 cursor-pointer" onClick={() => { setSelectedUserId(user.msId); setName(user.name)  }}>
                              <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">
                                {user.name}
                              </td>
                              <td className="px-6 py-4 whitespace-nowrap text-right text-sm font-medium">
                                <a href="#" className="text-indigo-600 hover:text-indigo-900">Select</a>
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                      <button onClick={ handleFormSubmit} className="bg-gray-800 text-gray-100 font-semibold py-2 px-4 rounded hover:bg-gray-700 transition ease-in-out duration-150 mt-4">Add Admin</button>
                      <button onClick={close} className="bg-gray-800 text-gray-100 font-semibold py-2 px-4 rounded hover:bg-gray-700 transition ease-in-out duration-150 mt-4">Close</button>
                    </div>
                  </div>
                </div>
              )}
            </Popup>
          </div>
        </div>
        <div className="block w-full overflow-x-auto">
          {/* Scrollable table container for categories */}
          <div style={{ maxHeight: '350px', overflowY: 'auto' }}>
            <table className="items-center w-full bg-transparent border-collapse">
              <thead>
                <tr>
                  <th className="px-6 bg-blueGray-50 text-blueGray-500 align-middle border border-solid border-blueGray-100 py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left">
                    Admin Names
                  </th>
                  <th className="px-6 bg-blueGray-50 text-blueGray-500 align-middle border border-solid border-blueGray-100 py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-center">
                    Actions
                  </th>
                </tr>
              </thead>
              <tbody>
                {categories.map((category, index) => (
                  <tr key={index}>
                    <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4 text-left">
                      {category.name}
                    </td>
                    <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4 text-right">
                      <button onClick={() => handleDelete(category._id)} className="text-red-500 hover:text-red-700">
                        Delete
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </>
  );
}
