import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { useMsal } from "@azure/msal-react";
import { loginRequest } from "AuthConfig";

// Components
import AdminNavbar from "components/Navbars/AdminNavbar.js";
import AuthContext from "Contexts/AuthContext";
import HeaderStats from "components/Headers/HeaderStats.js";

export default function Admin() {
  const history = useHistory();
  const { auth } = React.useContext(AuthContext);
  const { instance } = useMsal();
  const [isAuthChecked, setIsAuthChecked] = useState(false);

  function login() {
    instance.loginRedirect(loginRequest).catch(e => {
      console.error("Login error", e);
    });
  }

  useEffect(() => {
    if (!auth.loading && !auth.isAuth) {
      // If not authenticated and not loading, don't confirm auth checked yet
    } else if (!auth.loading && auth.isAuth) {
      setIsAuthChecked(true); // Auth is confirmed, proceed with rendering Admin component
    }
  }, [auth]);

  if (!isAuthChecked) {
    return (
      <div className="flex flex-col items-center justify-center min-h-screen bg-blueGray-100">
        <div className="text-lg text-center font-semibold mb-4">
          Looks like you are not admin, try logging in.
        </div>
        <button
          onClick={login}
          style={{ backgroundColor: "#4299E1" }}
          className="bg-blue-500 text-white active:bg-blue-600 font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
          type="button"
        >
          Login
        </button>
      </div>
    );
  }

  // Admin component's normal rendering logic
  return (
    <>
      <div className="bg-blueGray-100">
        <AdminNavbar />
        <HeaderStats />
        {/* Admin component's content */}
      </div>
    </>
  );
}
