import React, { useState, useEffect, useContext } from "react";
import axios from 'axios';
import Popup from 'reactjs-popup';
import 'reactjs-popup/dist/index.css';
import AuthContext from "Contexts/AuthContext"; // Adjust based on your project structure

export default function CardStudent() {
  const [categories, setCategories] = useState([]);
  const [selectedCategories, setSelectedCategories] = useState([]);
  const [note, setNote] = useState('');
  const [message, setMessage] = useState('');
  const [selectedDate, setSelectedDate] = useState('');
  const [forms, setForms] = useState([]);
  const [error, setError] = useState('');
  const { auth } = useContext(AuthContext);

  useEffect(() => {
    const fetchCategories = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_CLOSET_API}/products/`);
        setCategories(response.data);
        console.log(response.data)
      } catch (error) {
        setMessage(`Error fetching categories: ${error.response ? error.response.data.message : error.message}`);
      }
    };
    fetchCategories();
  }, [auth]);
  useEffect(() => {
    const fetchForms = async () => {
      try {
        if (auth.isAuth && auth.user) {
          const userId = auth.user.localAccountId;
          const response = await axios.get(`${process.env.REACT_APP_CLOSET_API}/products/getForm/${userId}`);
          console.log(response.data);
          setForms(response.data);
        }
      } catch (err) {
        setError(err.response ? err.response.data.message : err.message);
      }
    };
    fetchForms();
  }, [auth]);
  const handleCategoryChange = (e) => {
    const value = e.target.value;
    if (selectedCategories.includes(value)) {
      setSelectedCategories(selectedCategories.filter(category => category !== value));
    } else {
      setSelectedCategories([...selectedCategories, value]);
    }
  };

  const handleNoteChange = (e) => {
    setNote(e.target.value);
  };

  const handleDateChange = (e) => {
    setSelectedDate(e.target.value);
  };

  const handleFormSubmit = async (e) => {
    e.preventDefault();

    try {
      const locker = await axios.get(`${process.env.REACT_APP_CLOSET_API}/products/random-locker`)
      console.log(locker.data);
      const response = await axios.post(`${process.env.REACT_APP_CLOSET_API}/user/createForm`, {
        categories: selectedCategories,
        note,
        date: selectedDate,
        user: auth.user.localAccountId,
        locker: locker.data._id,
      });
      setForms([...forms, response.data]);
      setMessage('Form submitted successfully!');
    } catch (error) {
      setMessage(`Error submitting form: ${error.response ? error.response.data.message : error.message}`);
    }
  };

  return (
    <>
      <div className="relative flex flex-col min-w-0 break-words w-full mb-6  rounded">
        <div className="rounded-t mb-0 px-4 py-3 border-0 bg-blue-500 text-white bg-white">
          <div className="flex flex-wrap items-center justify-between bg-white">
          <div className="rounded-t mb-0 px-4 py-3 border-0">
    <div className="flex flex-wrap items-center justify-between">
      <h3 className="font-semibold text-base text-blueGray-700">Orders</h3>
    </div>
  </div>
  <Popup
  trigger={
    <button style={{
      backgroundColor: '#4299E1', color: 'white', fontWeight: 'bold', textTransform: 'uppercase',
      fontSize: '12px', padding: '6px 12px', borderRadius: '4px', boxShadow: '0 2px 4px 0 rgba(0,0,0,0.10)',
      transition: 'all 0.3s ease-in-out'
    }}>
      + New Order
    </button>
  }
  modal
  overlayStyle={{ background: 'rgba(0,0,0,0.5)' }}
  contentStyle={{
    padding: "20px",
    borderRadius: "10px",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  }}
>
  {close => (
    <div style={{ textAlign: 'center', width: '100%' }}>
      <h2 style={{ fontSize: '24px', fontWeight: 'bold', marginBottom: '20px', color: '#2D3748' }}>New Order</h2>
      <form onSubmit={handleFormSubmit} style={{ width: '100%', display: 'flex', flexDirection: 'column', gap: '20px' }}>
        <input
          type="date"
          value={selectedDate}
          onChange={handleDateChange}
          style={{ padding: '10px', borderRadius: '8px', border: '1px solid #CBD5E0', boxShadow: 'inset 0 1px 2px rgba(0,0,0,0.1)' }}
          required
        />
        {categories.map(category => (
          <div key={category._id} style={{ display: 'flex', alignItems: 'center' }}>
            <input
              type="checkbox"
              value={category.name}
              onChange={handleCategoryChange}
              checked={selectedCategories.includes(category.name)}
              style={{ width: '20px', height: '20px', accentColor: '#667EEA' }}
            />
            <span style={{ marginLeft: '8px', fontSize: '16px', color: '#4A5568' }}>{category.name}</span>
          </div>
        ))}
        <textarea
          value={note}
          onChange={handleNoteChange}
          style={{ padding: '10px', borderRadius: '8px', border: '1px solid #CBD5E0', boxShadow: 'inset 0 1px 2px rgba(0,0,0,0.1)' }}
          placeholder="Add a note (optional)"
        />
        <div style={{ display: 'flex', justifyContent: 'flex-end', gap: '10px' }}>
          <button
            type="button"
            onClick={close}
            style={{
              backgroundColor: '#E53E3E', color: 'white', fontWeight: 'bold', textTransform: 'uppercase',
              fontSize: '12px', padding: '6px 12px', borderRadius: '4px', boxShadow: '0 2px 4px 0 rgba(0,0,0,0.10)',
              transition: 'all 0.3s ease-in-out'
            }}
          >
            Cancel
          </button>
          <button
            type="submit"
            style={{
              backgroundColor: '#4299E1', color: 'white', fontWeight: 'bold', textTransform: 'uppercase',
              fontSize: '12px', padding: '6px 12px', borderRadius: '4px', boxShadow: '0 2px 4px 0 rgba(0,0,0,0.10)',
              transition: 'all 0.3s ease-in-out'
            }}
          >
            Submit
          </button>
        </div>
      </form>
      {message && <div style={{ marginTop: '16px', padding: '12px', backgroundColor: '#FED7D7', color: '#E53E3E', borderRadius: '4px' }}>{message}</div>}
    </div>
  )}
</Popup>


          </div>
        </div>
        
        <div className="relative flex flex-col min-w-0 break-words bg-white w-full mb-6 shadow-lg rounded">
  <div className="block w-full overflow-x-auto">
    {/* Table */}
    <table className="items-center w-full bg-transparent border-collapse">
      <thead>
        <tr className="bg-blueGray-50">
          <th className="px-6 bg-blueGray-50 text-blueGray-500 align-middle border border-solid border-blueGray-100 py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left">
            Categories
          </th>
          <th className="px-6 bg-blueGray-50 text-blueGray-500 align-middle border border-solid border-blueGray-100 py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left">
            Date
          </th>
          <th className="px-6 bg-blueGray-50 text-blueGray-500 align-middle border border-solid border-blueGray-100 py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left">
            Status
          </th>
          <th className="px-6 bg-blueGray-50 text-blueGray-500 align-middle border border-solid border-blueGray-100 py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left">
            Locker Info
          </th>
          <th className="px-6 bg-blueGray-50 text-blueGray-500 align-middle border border-solid border-blueGray-100 py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left">
             Note
          </th>
          <th className="px-6 bg-blueGray-50 text-blueGray-500 align-middle border border-solid border-blueGray-100 py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left">
            
          </th>
          
        </tr>
      </thead>
      <tbody>
      {forms.map((form, index) => (
  <tr key={index}>
    <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
      {form.categories.join(", ")}
    </td>
    <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
      {new Date(form.date).toLocaleDateString()}
    </td>
    <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
      <i className={`fas fa-circle ${form.approved ? 'text-green-500' : 'text-orange-500'}`}></i> {form.approved ? 'Approved' : 'Pending'}
    </td>
    <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
      {form.approved ? (
        <>
          Location: {form.locker.location}<br/>
          ID: {form.locker.locker_id}<br/>
          Combo: {form.locker.combo}
        </>
      ) : (
        "Please wait on Approval"
      )}
    </td>
    <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
      {form.note}
    </td>
    <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
      <i className="text-red-600 fas fa-trash text-lg leading-lg cursor-pointer" onClick={() => {/* add delete functionality here */}} />
    </td>
  </tr>
))}

      </tbody>
    </table>
  </div>



        </div>
      </div>
    </>
  );
}
