import React, { useState, useEffect, useContext } from "react";
import axios from 'axios';
import AuthContext from "Contexts/AuthContext";

export default function CardPageVisits({ userId }) {
  const [data, setData] = useState([]);
  const [error, setError] = useState('');
  const [edited, setEdited] = useState(false); // Track if any form has been edited
  const [user, setUser] = useState('');
  const { auth } = useContext(AuthContext);

  useEffect(() => {
    const fetchForms = async () => {
      try {
        console.log(auth);
        if(auth.isAuth){
          userId = auth.user.localAccountId;
        }
        const response = await axios.get(`${process.env.REACT_APP_CLOSET_API}/user/forms/`);
        const formsData = response.data.map(form => ({
          ...form,
          isEdited: false, // Add isEdited flag to track changes
        }));
        setData(formsData);
        console.log(formsData);
      } catch (err) {
        setError(err.response ? err.response.data.message : err.message);
      }
    };

    fetchForms();
  }, [userId, auth]);

  
  const handleInputChange = (index, field, value) => {
    const newData = [...data];
    newData[index][field] = value;
    newData[index].isEdited = true; // Mark as edited
    setData(newData);
    setEdited(true); // Set global edited state to true
  };

  const handleDelete = async (id) => {
    try {
      await axios.delete(`${process.env.REACT_APP_CLOSET_API}/products/prod/${id}`);
      setData(data.filter(form => form._id !== id));
      setError('Form deleted successfully!');
    } catch (err) {
      setError(err.response ? err.response.data.message : err.message);
    }
  };
 

  const handleSave = async () => {
    try {
      // Filter for edited forms only
      const editedForms = data.filter(form => form.isEdited);
      for (const form of editedForms) {
        await axios.patch(`${process.env.REACT_APP_CLOSET_API}/user/forms/${form._id}`, {
          note: form.note,
          date: form.date,
          approved: form.approved, // Assuming there's an 'approved' field to update
          teacherNote: form.teacherNote, // Assuming there's a 'teacherNote' field to update
        });
      }
      setError('Form(s) updated successfully!');
      // Reset edited state
      const updatedData = data.map(form => ({ ...form, isEdited: false }));
      setData(updatedData);
      setEdited(false); // Reset global edited state to false
    } catch (err) {
      console.log(err);
      setError(err.response ? err.response.data.message : err.message);
    }
  };

  return (
    <>
      {error && <div className="text-red-500 text-xs p-3">{error}</div>}
      <div className="relative flex flex-col min-w-0 break-words bg-white w-full mb-6 shadow-lg rounded">
        <div className="rounded-t mb-0 px-4 py-3 border-0 flex flex-wrap items-center justify-between">
          <div className="relative w-full max-w-full flex-grow flex-1">
            <h3 className="font-semibold text-base text-blueGray-700">
              Current Orders
            </h3>
          </div>
          {edited && ( // Conditionally render the Save button
            <div className="relative text-right">
              <button
                 className="bg-indigo-500 text-white active:bg-indigo-600 text-xs font-bold uppercase px-3 py-1 rounded outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                type="button"
                onClick={handleSave}
              >
                Save Changes
              </button>
            </div>
          )}
        </div>
        <div className="block w-full overflow-x-auto">
        <div style={{ maxHeight: '807px', overflowY: 'auto' }}>
          <table className="items-center w-full bg-transparent border-collapse">
            <thead>
              <tr>
                <th className="px-6 bg-blueGray-50 text-blueGray-500 align-middle border border-solid border-blueGray-100 py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left">
                  Student
                </th>
                <th className="px-6 bg-blueGray-50 text-blueGray-500 align-middle border border-solid border-blueGray-100 py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left">
                  Categories
                </th>
                <th className="px-6 bg-blueGray-50 text-blueGray-500 align-middle border border-solid border-blueGray-100 py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left">
                  Locker #
                </th>
                <th className="px-6 bg-blueGray-50 text-blueGray-500 align-middle border border-solid border-blueGray-100 py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left">
                  Note
                </th>
                <th className="px-6 bg-blueGray-50 text-blueGray-500 align-middle border border-solid border-blueGray-100 py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left">
                  Date
                </th>
                <th className="px-6 bg-blueGray-50 text-blueGray-500 align-middle border border-solid border-blueGray-100 py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left">
                  Status
                </th>
                <th className="px-6 bg-blueGray-50 text-blueGray-500 align-middle border border-solid border-blueGray-100 py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left">
                  Actions
                </th>
              </tr>
            </thead>
            <tbody>
              {data.map((formData, index) => (
                <tr key={index}>
                  <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-2 text-left">
                    {formData.userDetails.name}
                  </td>
                   {formData.categories.join(", ")}
                  <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                    {formData.locker.locker_id}
                  </td>
                  <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                    <textarea
                      className="form-input rounded-md shadow-sm mt-1 block w-full"
                      defaultValue={formData.note}
                      onBlur={(e) => handleInputChange(index, 'note', e.target.value)}
                    />
                  </td>
                  <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                    <input
                      type="date"
                      className="form-input rounded-md shadow-sm mt-1 block w-full"
                      defaultValue={new Date(formData.date).toISOString().split('T')[0]}
                      onBlur={(e) => handleInputChange(index, 'date', e.target.value)}
                    />
                  </td>
                  <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                    {formData.approved ? 'Approved' : 'Pending'}
                  </td>
                  <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4 flex flex-col justify-around items-center space-y-2">
  <button
    className="bg-lightBlue-500 text-white active:bg-lightBlue-600 text-xs font-bold uppercase px-3 py-1 rounded outline-none focus:outline-none ease-linear transition-all duration-150 w-full max-w-xs"
    type="button"
    onClick={() => handleInputChange(index, 'approved', !formData.approved)}
  >
    Toggle Approve
  </button>
  <h1 className="text-white">.</h1>
  <button
    className="bg-red-500 text-white active:bg-red-600 text-xs font-bold uppercase px-3 py-1 rounded outline-none focus:outline-none ease-linear transition-all duration-150 w-full max-w-xs"
    type="button"
     onClick={() => handleDelete(formData._id)}
  >
    Delete
  </button>
</td>
                </tr>
              ))}
            </tbody>
          </table>
          </div>
        </div>
      </div>
    </>
  );
}
