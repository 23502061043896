import React, { useState, useEffect } from "react";
import axios from 'axios';
import Popup from 'reactjs-popup';
import 'reactjs-popup/dist/index.css'; // Ensure you have this for default popup styles if you're using them

export default function CardSocialTraffic() {
  const [categoryName, setCategoryName] = useState('');
  const [categories, setCategories] = useState([]);
  const [message, setMessage] = useState('');

  useEffect(() => {
    const fetchCategories = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_CLOSET_API}/products`);
        setCategories(response.data);
      } catch (error) {
        setMessage(`Error fetching categories: ${error.response ? error.response.data.message : error.message}`);
      }
    };
    fetchCategories();
  }, []);

  const handleInputChange = (e) => {
    setCategoryName(e.target.value);
  };

  const handleFormSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post(`${process.env.REACT_APP_CLOSET_API}/products`, { name: categoryName });
      setCategories([...categories, response.data]);
      setMessage(`Category ${response.data.name} created successfully!`);
      setCategoryName('');
    } catch (error) {
      setMessage(`Error creating category: ${error.response ? error.response.data.message : error.message}`);
    }
  };

  const handleDelete = async (id) => {
    try {
      await axios.delete(`${process.env.REACT_APP_CLOSET_API}/products/${id}`);
      setCategories(categories.filter(category => category._id !== id));
      setMessage('Category deleted successfully!');
    } catch (error) {
      setMessage(`Error deleting category: ${error.response ? error.response.data.message : error.message}`);
    }
  };

  return (
    <>
      {message && <div className="text-red-500 text-sm p-3">{message}</div>}
      <div className="relative flex flex-col min-w-0 break-words bg-white w-full mb-6 shadow-lg rounded">
        <div className="rounded-t mb-0 px-4 py-3 border-0">
          <div className="flex flex-wrap items-center justify-between">
            <h3 className="font-semibold text-base text-blueGray-700">
              Categories
            </h3>
            <Popup
              trigger={<button className="bg-gray-800 text-gray-100 font-semibold py-2 px-4 rounded hover:bg-gray-700 transition ease-in-out duration-150">+ New Category</button>}
              modal
              nested
              overlayStyle={{ background: 'rgba(0,0,0,0.5)' }}
            >
              {close => (
                <div className="modal bg-white rounded-lg shadow-lg max-w-md mx-auto mt-10 p-8">
                  <div className="header mb-4">
                    <div className="font-semibold text-xl text-gray-800">New Category</div>
                  </div>
                  <form onSubmit={handleFormSubmit} className="flex flex-col space-y-5">
                    <input 
                      className="appearance-none border border-gray-300 rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:border-gray-500" 
                      placeholder="Category Name" 
                      type="text" 
                      value={categoryName} 
                      onChange={handleInputChange} 
                      required 
                    />
                    <div className="flex justify-end space-x-3">
                      <button 
                        onClick={close} 
                        className="text-gray-600 border border-gray-300 font-semibold py-2 px-4 rounded hover:bg-gray-50 transition ease-in-out duration-150"
                        type="button"
                      >
                        Cancel
                      </button>
                      <button 
                        className="bg-blue-500 text-white font-semibold py-2 px-4 rounded hover:bg-blue-600 transition ease-in-out duration-150" 
                        type="submit"
                      >
                        Submit
                      </button>
                    </div>
                  </form>
                </div>
              )}
            </Popup>
          </div>
        </div>
        <div className="block w-full overflow-x-auto">
          {/* Scrollable table container */}
          <div style={{ maxHeight: '350px', overflowY: 'auto' }}>
            <table className="items-center w-full bg-transparent border-collapse">
              <thead>
                <tr>
                  <th className="px-6 bg-blueGray-50 text-blueGray-500 align-middle border border-solid border-blueGray-100 py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left">
                    Tags
                  </th>
                  <th className="px-6 bg-blueGray-50 text-blueGray-500 align-middle border border-solid border-blueGray-100 py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-center min-w-140-px"></th>
                </tr>
              </thead>
              <tbody>
                {categories.map((category, index) => (
                  <tr key={index}>
                    <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4 text-left">
                      {category.name}
                    </td>
                    <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4 text-right hover:cursor-pointer" onClick={() => handleDelete(category._id)}>
                      X
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </>
  );
}
