import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";

import "@fortawesome/fontawesome-free/css/all.min.css";
import "assets/styles/tailwind.css";
import { useEffect, useState } from "react";
import { useMsal } from "@azure/msal-react";
import { loginRequest } from "./AuthConfig";
import axios from "axios";
import AuthContext from "Contexts/AuthContext"


import Admin from "layouts/Admin.js";
import Student from "layouts/Student";

// views without layouts

import Landing from "views/Landing.js";
import Profile from "views/Profile.js";
import Index from "views/Index.js";
import AdminContext from "Contexts/AdminContext";

//Routes
import SuperAdmin from "AuthRoutes/SuperAdmin";
import AuthRoute from "AuthRoutes/AuthRoute";
import AdminRoute from "AuthRoutes/AdminRoute";
import Dashboard from "views/admin/Dashboard";

function App() {
    const [auth, setAuth] = useState({
        isAuth: false,
        loading: true,
        fetched: false,
        failed: false,
       
      });
      const [admin, setAdmin] = useState({
        admin: false,
      });
      const { instance, accounts } = useMsal();
      
      useEffect(() => {

        document.title = "Roary's Closet";

        if (accounts[0]) {
          instance
            .acquireTokenSilent({
              ...loginRequest,
              account: accounts[0],
            })
            .then((response) => {
              const resp = {...response.account, grade: parseInt(response.account.grade)};
              setAuth((prev) => ({
                ...prev,
                token: response.accessToken,
                user: resp,
              }));
              
              testToken(response.accessToken);
            });
        }
    
      }, [accounts]);
    
   
      async function signOutClickHandler(instance) {
        const selectionRes = await axios.post(
          `${process.env.REACT_APP_CLOSET_API}/user`,
          {
            user: auth,
          }
        );
        console.log(selectionRes, "SELECTION RES");
        if (!selectionRes.data.errors) {
          setAuth((prev) => ({
            isAuth: true,
            user: { ...prev.user },
            loading: false,
            fetched: true,
          }));
        }
      
      }
    
      const testToken = async (token) => {
        const res = await axios.get("https://graph.microsoft.com/v1.0/me", {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        
        if (
          res.data.jobTitle == "12" ||
          res.data.jobTitle == "11" ||
          res.data.jobTitle == "10" ||
          res.data.jobTitle == "9"
        ) {
    
          setAuth((prev) => ({
            ...prev,
            isAuth: true,
            loading: false,
            fetched: false,
            user: {
              ...prev.user,
              role: "student",
              displayName: `${res.data.givenName} ${res.data.surname}`,
              grade: res.data.jobTitle,
            },
          }));
          console.log(auth);
          

        }else{
          setAuth((prev) => ({
            ...prev,
            isAuth: true,
            loading: false,
            fetched: false,
            user: {
              ...prev.user,
              role: "teacher",
              displayName: `${res.data.givenName} ${res.data.surname}`,
              grade: res.data.jobTitle,
            },
          }));
        }
        const adminCheck= await axios.get(`${process.env.REACT_APP_CLOSET_API}/products/admin/${res.data.id}`);
        if(adminCheck.data){
          setAdmin({
            admin: true,
          });
        }
       
      };
    
    
    useEffect(() => {
      const fetchData = async () => {
        if (auth.user && !auth.fetched) {
          console.log("FETCHING");
          try {
         
            const selectionRes = await axios.post(
              `${process.env.REACT_APP_CLOSET_API}/user`,
              {
                user: auth,
              }
            );
            console.log(selectionRes, "SELECTION RES");
            if (!selectionRes.data.errors) {
              setAuth((prev) => ({
                isAuth: true,
                user: { ...prev.user },
                loading: false,
                fetched: true,
              }));
            }
            
          } catch (error) {
            console.log(error);
          }
        }
      };
    
      fetchData();
    }, [auth]);

  return (
    <div>
        <AuthContext.Provider value={{ auth, setAuth }}>
        <AdminContext.Provider value={{ admin, setAdmin }}>
        <BrowserRouter>
            <Switch>
              <Route path="/admin" component={Admin} />
              <Route path="/student" component={Student} />
            
            
                    <Route path="/" exact component={Student} />
           
         
            </Switch>
        </BrowserRouter>
        </AdminContext.Provider>
 </AuthContext.Provider>
    </div>
  )
}

export default App