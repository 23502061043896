import React from "react";
import CardPageVisits from "components/Cards/CardPageVisits.js";
import CardSocialTraffic from "components/Cards/CardSocialTraffic.js";
import StudentProfiles from "components/Cards/StudentProfiles";
// components

import CardStats from "components/Cards/CardStats.js";

export default function HeaderStats() {
  return (
    <>
      {/* Header */}
      <div className="relative  md:pt-32 pb-10 pt-12">
        <div className="px-4 md:px-10 mx-auto w-full">
          <div>
          <>
      <div className="flex flex-wrap">
      </div>
      <div className="flex flex-wrap mt-4">
        <div className="w-full xl:w-8/12 mb-12 xl:mb-0 px-4">
          <CardPageVisits />
        </div>
        <div className="w-full xl:w-4/12 px-4">
         
          <CardSocialTraffic />
        
          <StudentProfiles />
        </div>
        
      </div>
    </>
          </div>
        </div>
      </div>
    </>
  );
}
