import React from "react";

import UserDropdown from "components/Dropdowns/UserDropdown.js";
import AuthContext from "Contexts/AuthContext";
import { useMsal } from "@azure/msal-react";
export default function Navbar() {
  const { instance } = useMsal();
  const { auth, setAuth} = React.useContext(AuthContext);
     
       function signOutClickHandler() {
        instance.logoutPopup().catch(e => {
          console.error("Logout error", e);
        });
        setAuth({isAuth: false, fetched:false, loading: false});
        window.location.reload();
      }

  return (
    <div className="relative ">
      {/* Navbar */}
      <nav style={{ backgroundColor: 'white', borderBottom: "solid", borderBottomWidth:'.1px', borderColor:'lightgray'}} className="absolute  top-0 left-0 w-full z-10 md:flex-row md:flex-nowrap md:justify-start flex items-center p-4 background-color: blue">
        <div className="w-full mx-autp items-center flex justify-between md:flex-nowrap flex-wrap md:px-10 px-20">
          {/* Brand */}
          <div style={{ display: "flex", alignItems: "center", gap: "10px" }}>
          <img style={{width:"80px"}}src="https://hseclubs.com/static/media/hseapps.6c966a37.png"/>
          <h1 style={{fontSize:"25px"}}
            className="text-black text-sm uppercase hidden lg:inline-block"
            
            onClick={(e) => e.preventDefault()}
          >
            Roary's Closet 
          </h1>
          </div>
          {/* Form */}
       
          {/* User */}
          <ul className="flex-col md:flex-row list-none items-center hidden md:flex">
            <button onClick={signOutClickHandler} style={{backgroundColor:"#4299E1"}} className="text-white font-semibold py-2 px-4 rounded hover:bg-red-600 transition ease-in-out duration-150">Sign Out</button>
          </ul>
        </div>
      </nav>
      {/* End Navbar */}
    </div>
  );
}
