import React,{useState, useEffect} from "react";
import { Switch, Route, Redirect } from "react-router-dom";

// components

import StudentNavbar from "components/Navbars/StudentNavbar.js";

import StudentHeader from "components/Headers/StudentHeader";
import CardStudent from "components/Cards/CardStudent";


import { useHistory } from "react-router-dom";
import { useMsal } from "@azure/msal-react";
import { loginRequest } from "AuthConfig";
import AuthContext from "Contexts/AuthContext";
import Landing from "views/Landing";




export default function Student() {
 
  const history = useHistory();
  const { auth } = React.useContext(AuthContext);
  const { instance } = useMsal();
  const [isAuthChecked, setIsAuthChecked] = useState(false);

  function login() {
    instance.loginRedirect(loginRequest).catch(e => {
      console.error("Login error", e);
    });
  }

  useEffect(() => {
    if (!auth.loading && !auth.isAuth) {
      // If not authenticated and not loading, don't confirm auth checked yet
    } else if (!auth.loading && auth.isAuth) {
      setIsAuthChecked(true); // Auth is confirmed, proceed with rendering Admin component
    }
  }, [auth]);

  if (!isAuthChecked) {
    return (
    
        <Landing />
  
    );
  }

  return (
    <>
     
      <div className=" bg-blueGray-100">
        <StudentNavbar />
        {/* Header */}
        <StudentHeader />
      
      </div>
    </>
  );
}
